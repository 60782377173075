import React from 'react';
import { NavLink } from 'react-router-dom';

import { config } from '../../config';
import { ExternalLinkIcon } from '../Icons';

import s from './s.module.css';

export function Footer() {
  return (
    <footer className={s.footer}>
      <nav>
        <ul className={s.navList}>
          <li className={s.navListItem}>
              <a
                href={config.links.orbitalBurn}
                target="_blank"
                rel="noopener noreferrer"
                className={s.navLink}
              >
                Orbital Burn
                <ExternalLinkIcon className={s.navLinkExternalIcon} />
              </a>
            </li>
          <li className={s.navListItem}>
            <NavLink
              exact
              to="/"
              className={s.navLink}
              activeClassName={s.navLinkActive}
            >
              Home
            </NavLink>
          </li>
          <li className={s.navListItem}>
            <NavLink
              to="/rules"
              className={s.navLink}
              activeClassName={s.navLinkActive}
            >
              Rules
            </NavLink>
          </li>
          <li className={s.navListItem}>
            <NavLink
              to="/events"
              className={s.navLink}
              activeClassName={s.navLinkActive}
            >
              Events
            </NavLink>
          </li>
          <li className={s.navListItem}>
            <NavLink
              to="/check"
              className={s.navLink}
              activeClassName={s.navLinkActive}
            >
              Verify connectivity
            </NavLink>
          </li>
        </ul>
      </nav>
      <p className={s.copyright}>Copyright © 2020 Filecoin Project</p>
    </footer>
  );
}
